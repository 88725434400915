<template>
  <v-container cols="12" class="pl-1 pr-1 mt-5 mb-5">
    <v-row>
      <v-col cols="6" sm="4" md="3" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('machines.setup-time')
            }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ val(item, 'setup_time') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="6" sm="4" md="2" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('machines.efficiency')
            }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.efficiency }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="6" sm="4" md="4" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('unit') }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ val(item, 'efficiency_unit') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="6" sm="4" md="4" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('machines.pause-before-start')
            }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ val(item, 'pre_delay') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="6" sm="4" md="4" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('machines.pause-after-completion')
            }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ val(item, 'post_delay') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="6" sm="4" md="4" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('machines.waste') }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.waste }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="6" sm="4" md="4" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('machines.setup-waste')
            }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.setup_waste }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="6" sm="4" md="4" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('machines.is-default')
            }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ item.is_default ? $t('yes') : $t('no') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      required: true
    },
    headers: {
      type: Array,
      required: true
    }
  },
  computed: {},
  methods: {
    val(item, field) {
      let v = item[field];
      if (v && v.length > 0) {
        if (field == 'efficiency_unit') {
          return this.$t(`units.${v}`);
        }

        return v;
      }
      return '----';
    }
  }
};
</script>

<style></style>
